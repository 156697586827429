import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'
import ViewMenuButton from './ViewMenuButton'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${mobileHero}) bottom/ cover no-repeat`}
          column
          justifyStart
          alignCenter
        >
          <Header />
          <CFView column justifyStart alignCenter>
            <CFView white xBold style={{ fontSize: 36 }}>
              ARIGATO SUSHi
            </CFView>
            <CFView white xBold style={{ fontSize: 36 }}>
              IN KAMLOOPS
            </CFView>
            <CFView pulsate mv="15px" textCenter>
              <OrderPickupButton />
            </CFView>
            <CFView textCenter>
              <ViewMenuButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) top / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="rgba(0,0,0,0.9)" ph="25px" pv="5px">
            <CFView bold white style={{ fontSize: 30 }}>
              WELCOME TO ARIGATO SUSHI!
            </CFView>
            <CFView ml="30px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
